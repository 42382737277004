import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'


const Pediatrics = () => {
    return (
        <div>
            <Container>
                <Row className='d-flex justify-content-center mb-5'>
                    <h3 className='text-center mt-5'>Pediatrics</h3>
                    <Col  md={8}>
                        <p style={{textAlign:'justify'}}>
                            <h6 className='mt-5'>Nutritional Supplements :</h6>
                            Nutritional supplements for babies play a vital role in supporting their growth and development during the early stages of life. These supplements are often formulated with essential vitamins and minerals that are crucial for immune function, bone health, and overall well-being. They can help fill dietary gaps, ensuring that infants receive a balanced intake of nutrients, especially if they have specific dietary restrictions or if their nutritional needs aren’t fully met through food alone.

                             <h6 className='mt-5'>Digestive Aids :</h6>
                            Digestive aids are specifically designed to support babies experiencing common gastrointestinal issues such as colic, gas, or indigestion. These products typically contain natural ingredients that promote healthy digestion and soothe discomfort. By enhancing digestive health, these aids not only improve your baby’s comfort but also facilitate better nutrient absorption, allowing them to gain maximum benefit from their diet.

                             <h6 className='mt-5'>Probiotics :</h6>
                            Probiotics are beneficial bacteria that can significantly enhance gut health in infants. These products help maintain a balanced microbiome, which is essential for proper digestion and immune support. Incorporating probiotics into your baby's diet can promote a healthy digestive system, which is crucial for nutrient absorption and overall health. They can also help reduce the incidence of digestive disturbances, providing a natural way to support your baby’s health.

                             <h6 className='mt-5'>Iron and Mineral Supplements :</h6>
                            Iron and mineral supplements are important for babies, particularly as they transition to solid foods. These supplements help prevent deficiencies that can affect cognitive development and overall health. They are formulated to be gentle on a baby's stomach and are designed to provide the necessary amounts of iron and other essential minerals in a safe and effective manner. Ensuring adequate iron intake is especially important for babies who may be at risk for anemia.

                             <h6 className='mt-5'>Herbal Remedies :</h6>
                            Herbal remedies can be a gentle and natural way to support various aspects of baby health. Many of these products are formulated with traditional herbs known for their soothing properties, particularly for digestive health or immune support. These remedies are often used to help alleviate common discomforts, allowing parents to provide their infants with natural solutions while promoting overall wellness.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Pediatrics