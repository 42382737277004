import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'

const Nutrtion = () => {
    return (
        <div>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <h4 className='text-center mt-5' >Know More About Nutrtion With Us</h4>
                    <Col md={7}>
                        <p style={{ textAlign: 'justify' }}>

                            <h6 className='mt-5' >Comprehensive Nutrition :</h6>
                            In our Gramodyog section, we offer a wide range of products designed to complete the nutritional profile that is often lacking in today’s food supply. Many modern diets fail to provide essential nutrients due to processed foods and the use of synthetic ingredients. Our carefully curated selection focuses on wholesome, natural ingredients that ensure your family receives the vitamins and minerals needed for optimal health.

                            <h6 className='mt-5' >Quality Assurance :</h6>
                            In an age where many food products are compromised by chemical additives and artificial enhancements, we take pride in restoring quality to your nutrition. Our Gramodyog products are crafted with the utmost care, using traditional methods that prioritize purity and integrity. By avoiding harmful chemicals and focusing on natural sourcing, we bring back the quality that is increasingly rare in contemporary food options.

                            <h6 className='mt-5' >Nutrient-Rich Options :</h6>
                            Our Gramodyog section features nutrient-rich options that are not only beneficial but also flavorful. From whole grains to natural sweeteners, each product is designed to enhance your diet and contribute positively to your well-being. We believe that healthy eating should not come at the expense of taste, and our offerings reflect this commitment.

                            <h6 className='mt-5' >Supporting Local Agriculture :</h6>
                            By choosing our Gramodyog products, you are not only investing in your health but also supporting local agriculture. We partner with local farmers who share our dedication to sustainable practices and high-quality produce. This not only ensures fresher ingredients but also helps to strengthen the community and promote responsible farming methods.

                            <h6 className='mt-5' >Educating Consumers :</h6>
                            We are passionate about educating our customers on the importance of nutritional integrity. By understanding what goes into our food, we empower you to make informed choices for yourself and your family. Our Gramodyog section is not just a product line; it’s a commitment to health, quality, and transparency in what you consume.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Nutrtion