import React from 'react'
import '../../Styles/contact.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Contact = () => {
  return (
    <div>
      <Container>
        <Row className='d-flex justify-content-around'>
          <h4 className='mb-5 mt-5'>Our Contact Information</h4>
          <Col md={3} className='box_shadow'>
            <p className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
            </svg><b><br />9450535983 </b></p>
          </Col>
          <Col md={3} className='box_shadow' >
            <p className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
            </svg><br /><b>khadiindiasharp@gmail.com</b></p>
          </Col>
        </Row>
        <Row className='d-flex justify-content-around mt-3 mb-3'>
          <Col md={3} className='box_shadow'>
            <p className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            </svg><br /><b>Plot No 121, 122 Maheshpur Industrial Estate, Kashi Vidya Peeth Block Road, Industrial Estate, Varanasi - 221106</b></p>
          </Col>
          <Col md={3} className='box_shadow' >
            <p className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-radar" viewBox="0 0 16 16">
              <path d="M6.634 1.135A7 7 0 0 1 15 8a.5.5 0 0 1-1 0 6 6 0 1 0-6.5 5.98v-1.005A5 5 0 1 1 13 8a.5.5 0 0 1-1 0 4 4 0 1 0-4.5 3.969v-1.011A2.999 2.999 0 1 1 11 8a.5.5 0 0 1-1 0 2 2 0 1 0-2.5 1.936v-1.07a1 1 0 1 1 1 0V15.5a.5.5 0 0 1-1 0v-.518a7 7 0 0 1-.866-13.847" />
            </svg><br /><b>Working Areas</b><br /><b>PAN India</b></p>
          </Col>
        </Row>
        <h5 className='text-center mt-5 mb-5'>Reach Us Through Google Map</h5>
        <Row className='d-flex justify-content-center mt-5 mb-5'>
          <Col md={9} className='location_box'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d379.12519045288343!2d82.9606837593799!3d25.308672483017325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2d862b046bb1%3A0x9be2831374ef9a0f!2s8X56%2BF73%2C%20industrial%20Area%2C%20Maheshpur%2C%20Varanasi%2C%20Maruadih%20Rly.%20Settlement%2C%20Uttar%20Pradesh%20221106!5e0!3m2!1sen!2sin!4v1728198762629!5m2!1sen!2sin" title='location' width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact