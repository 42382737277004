import React from 'react'
import '../../Styles/SearchProduct.css'
import Button from 'react-bootstrap/Button';
import { useLocation, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { baseURL } from '../../baseURL';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion'

const SearchProduct = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const Products = location.state?.data
  const addToCart = (_id) => {
    const ProductId = _id
    const count = 1
    axios({
      url: `${baseURL}/api/v1/cart/addToCart`,
      withCredentials: true,
      method: 'POST',
      data: ({ ProductId, count }),
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('user')}`
      }
    }).then((res) => {
      if (res.status === 200) {
        alert('Item Added to Cart')
        window.location.reload()
      } else {
        alert('Response Error')
      }
    }).catch((err) => {
      if (err.status === 401) {
        alert('Please Login First')
      } else if (err.status === 400) {
        alert('Product Problem')
      } else if (err.status === 404) {
        alert('Create Your Cart')
        navigate('/Cart')
      } else {
        alert('Server Error')
      }
    })
  }

  return (
    <div className='mt-5'>
      {
        Products.map((item) =>
          <Container className='shadow-lg p-3 mb-5 bg-body-tertiary rounded'>
            <Row>
              <Col md={5}>
                <h4 className='text-start'>{item.ProductName}</h4>
                <p className='mb-5 fw-bold'>{item.ProductId.substring(0, 2) === 'AC' ? '(Classical)' : item.ProductId.substring(0, 2) === 'AP' ? '(Patient)' : ''}</p>
                <h6>Product Id</h6>
                <p>{item.ProductId}</p>
                <h6 className='mt-2'>Size</h6>
                <div className="size-box mb-3">{item.PackingSize}</div>
                <h6 className='mt-2'>Price</h6>
                <div className="price-box mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                </svg> {item.Price} /-</div>
                <h6 className='mt-2'>Shelf Life</h6>
                <p>{item.shelf_life}</p>
                <h6 className='mt-5' >Product Description</h6>
                <p style={{ textAlign: 'justify' }} > {item.description} </p>
                <div className="text-center mt-2">
                  <Button onClick={(() => addToCart(item._id))} variant='warning'>Add To Cart</Button>
                </div>
              </Col>
              <Col md={7}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Disclamer</Accordion.Header>
                    <Accordion.Body>This Image is Only For Reference Packaging may differ according to Size.</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Carousel fade>
                  {
                    item.Image.map((image) =>
                      <Carousel.Item>
                        <Image src={image} fluid rounded />
                      </Carousel.Item>
                    )
                  }
                </Carousel>
              </Col>
            </Row>
          </Container>
        )
      }
    </div>
  )
}

export default SearchProduct