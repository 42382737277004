import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import Header from './Assests/Components/Header/header.jsx';
import Body from './Assests/Components/Body/body.jsx';
import Footer from './Assests/Components/Body/Footer.jsx';
import About from './Assests/Components/Body/About.jsx';
import Products from './Assests/Components/Body/Products.jsx';
import Services from './Assests/Components/Body/Services.jsx';
import Contact from './Assests/Components/Body/Contact.jsx';
import Login from './Assests/Components/User/Login.jsx';
import Register from './Assests/Components/User/Register.jsx';
import Profile from './Assests/Components/User_Dashboards/Profile.jsx';
import PrivateComponents from './Assests/Components/User_Dashboards/PrivateComponents.jsx'
import Cart from './Assests/Components/User_Dashboards/Cart.jsx';
import Setting from './Assests/Components/User_Dashboards/Setting.jsx';
import ProductDetails from './Assests/Components/Products/ProductDetails.jsx';
import SearchProduct from './Assests/Components/Products/SearchProduct.jsx';
import Bills from './Assests/Components/User_Dashboards/Bills.jsx';
import Educational from './Assests/Components/Footer/Educational.jsx';
import Nutrtion from './Assests/Components/Footer/Nutrtion.jsx';
import Guides from './Assests/Components/Footer/Guides.jsx';
import Pediatrics from './Assests/Components/Footer/Pediatrics.jsx';
import Terms from './Assests/Components/Footer/Terms.jsx';
import Privacy from './Assests/Components/Footer/Privacy.jsx';
import ScrollToTop from './Assests/Components/Body/ScrollToTop.jsx';

function App() {
  return (
    <div className="App">
      <ScrollToTop/>
      <Header />
      <Routes>
        <Route path='/' Component={Body} ></Route>
        <Route path='/About_Us' Component={About}></Route>
        <Route path='/Products' Component={Products}></Route>
        <Route path='/Services' Component={Services}></Route>
        <Route path='/Contact_Us' Component={Contact}></Route>
        <Route path='/Login' Component={Login}></Route>
        <Route path='/Register' Component={Register}></Route>
        <Route path='/ProductDetails/:category' Component={ProductDetails}></Route>
        <Route path='/Searched' element={<SearchProduct/>}></Route>
        <Route element={<PrivateComponents />}>
          <Route path='/Profile' Component={Profile}></Route>
          <Route path='/Cart' Component={Cart}></Route>
          <Route path='/Settings' Component={Setting}></Route>
          <Route path='/Bills' Component={Bills}></Route>
        </Route>
        <Route path='/Educational' Component={Educational}></Route>
        <Route path='/Pediatrics' Component={Pediatrics}></Route>
        <Route path='/Nutrition' Component={Nutrtion}></Route>
        <Route path='/Guides' Component={Guides}></Route>
        <Route path='/Terms' Component={Terms}></Route>
        <Route path='/Privacy' Component={Privacy}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
