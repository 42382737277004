import React, { useEffect, useState } from 'react'
import '../../Styles/header.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import axios from 'axios';
import { baseURL } from '../../baseURL';
import Cookies from 'js-cookie';



const Header = () => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const auth = localStorage.getItem('user')

    const getDetails = async () => {
        if (auth) {
            axios({
                method: 'GET',
                withCredentials: true,
                url: `${baseURL}/api/v1/user/getDetails`,
                headers: {
                    'Authorization': `Bearer ${auth}`
                }
            }).then((res) => {
                setData(res.data.data)
            }).catch((err) => {
                setData([]);
            })
        } else {
            setData([]);
        }
    }


    useEffect(() => {
        getDetails();
        if (auth) {
            getCount();
        }
        
        // eslint-disable-next-line 
    }, [])

    const logout = () => {
        axios({
            method: 'POST',
            withCredentials: true,
            url: `${baseURL}/api/v1/user/logout`,
            headers: {
                'Authorization': `Bearer ${auth}`
            }
        }).then((res) => {
            if (res.status === 200) {
                localStorage.clear();
                Cookies.remove('refreshToken');
                window.location.reload();
                navigate('/')
            } else {
                alert('Response Server Error')
            }
        }).catch((err) => {
            const message = err.message;
            const code = message.substring(message.length - 3);
            if (code === '401') {
                alert('Unauthorised Request')
            } else {
                alert('Server Error')
            }
        })
    }

    const getCount = async () => {
        axios({
            url: `${baseURL}/api/v1/cart/cartCount`,
            method: 'GET',
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${auth}`
            }
        }).then((res) => {
            if (res.status === 200) {
                setCount(res.data.data.count)
            } else {
                alert('Response Server Error')
            }
        }).catch((err) => {
            if (err.status === 401) {
                alert('Login Failure')
            } else if (err.status === 404) {
                setCount(0);
            } else {
                alert('Server Error')
            }
        })
    }

    return (
        <div>
            <Navbar expand="lg" className="Nav_Background">
                <Container>
                    <Navbar.Brand className='Company_Name'> <Image src={require('../../Images/Sharp logo.png')}/> Sharp Laboratories</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link className='Nav-Link' href="/">Home</Nav.Link>
                            <Nav.Link className='Nav-Link' href="/About_Us">About Us</Nav.Link>
                            <Nav.Link className='Nav-Link' href="/Products">Products</Nav.Link>
                            <Nav.Link className='Nav-Link' href="/Services">Services</Nav.Link>
                            <Nav.Link className='Nav-Link' href="/Contact_Us">Contact Us</Nav.Link>
                        </Nav>
                        <Nav className='me-end'>
                            {
                                auth ?
                                    <div>
                                        <NavDropdown active title={data.name} id="collapsible-nav-dropdown">
                                            <NavDropdown.Item href="/Profile"><Image src="" rounded /> Profile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                                <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                                            </svg></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="/Settings">Change Password</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="/Cart"><div className='d-flex'>
                                            Cart<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cart" style={{marginLeft:"5px"}} viewBox="0 0 16 16">
                                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                            </svg><div className="badges">{count}</div></div></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item onClick={logout}>Logout <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                                                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                                            </svg></NavDropdown.Item>
                                        </NavDropdown>
                                    </div>
                                    :
                                    <div>
                                        <Button onClick={(() => navigate('/Login'))}>Login</Button>{' '}
                                        <Button onClick={(() => navigate('/Register'))} variant='success'>Sign Up</Button>
                                    </div>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header