import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'

const Terms = () => {
    return (
        <div>
            <Container>
                <Row className='d-flex justify-content-center mb-5'>
                    <h3 className='text-center mt-5' >Terms Of Use</h3>
                    <Col md={7}>
                        <p style={{textAlign:'justify'}}>
                            <h6 className='mt-5' >Order Processing :</h6>
                            At Sharp Laboratories, we understand the importance of timely service. Therefore, we ensure that all orders are processed efficiently. Please note that your order will be processed only after full payment has been received. This policy allows us to maintain a high standard of service and ensure that every order is fulfilled accurately and promptly.

                            <h6 className='mt-5' >Product Verification :</h6>
                            We are committed to providing our customers with safe and effective products. Before dispatch, each item undergoes rigorous verification to confirm that it is properly registered and has been thoroughly tested. We also ensure that all products are within their expiration dates and have adequate shelf life, allowing you to shop with confidence.

                            <h6 className='mt-5' >Return Policy :</h6>
                            Due to the sensitive nature of pharmaceutical products, we maintain a strict no-return policy. Once your order is confirmed and dispatched, it cannot be returned or exchanged, except in cases where an error has occurred on our part or if the product is found to be defective. We encourage you to review your order carefully to ensure it meets your needs.

                            <h6 className='mt-5' >Customer Responsibility :</h6>
                            We believe in empowering our customers to make informed choices. By placing an order with us, you acknowledge your responsibility to ensure that the products you select are suitable for your intended use. If you have any questions or concerns about a specific product, we recommend consulting with a healthcare professional before making your purchase.

                            <h6 className='mt-5' >Compliance and Regulations :</h6>
                            At Sharp Laboratories, we prioritize compliance with all applicable laws and regulations regarding the sale of pharmaceutical products. By placing an order, you agree to adhere to these standards, ensuring that your purchasing practices align with legal requirements. Your understanding and cooperation help us maintain a safe shopping environment for all customers.

                            <h6 className='mt-5' >Customer Support :</h6>
                            Our dedicated customer service team is here to assist you with any inquiries or concerns you may have. Whether you have questions about your order, product information, or our policies, we are just a call or email away. At Sharp Laboratories, your satisfaction is our top priority, and we strive to provide you with the support you need.
                        </p>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Terms