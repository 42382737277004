import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'


const Guides = () => {
    return (
        <div>
            <Container>
                <Row className='d-flex justify-content-center mb-5'>
                    <h4 className='text-center mt-5'> Medication Guides By Sharp Laboratories </h4>
                    <Col md={7}>
                        <p style={{ textAlign: 'justify' }}>
                            <h6 className='mt-5' >Embracing Natural Wellness: Medication Product Guides</h6>

                            At our company, we prioritize your health and well-being by offering medication product guides that focus on organic and natural solutions. These products are designed to minimize side effects, allowing you to embrace a holistic approach to wellness. By harnessing the power of nature, our offerings provide effective relief while promoting overall health. We believe that when you choose products rooted in organic ingredients, you not only care for your body but also support sustainable practices.

                            <h6 className='mt-5' >Strengthening Immunity: Enhancing Your Health</h6>

                            In addition to our focus on natural remedies, we also provide a range of products specifically aimed at enhancing your immune system. A robust immune system is essential for maintaining good health, especially in today’s fast-paced world. Our guides help you select the right supplements and lifestyle changes to boost your immunity, making it easier for you to fend off illnesses and feel your best. With our expertise, you can improve your life by taking proactive steps toward a stronger, more resilient you.

                            <h6 className='mt-5' >Improving Lifestyle: Mindfulness and Well-Being</h6>

                            Beyond products and supplements, we emphasize the importance of adopting a mindful lifestyle. Incorporating practices such as meditation, yoga, and regular exercise can significantly enhance your overall well-being. Our guides provide insights on integrating these practices into your daily routine, promoting mental clarity and emotional balance. By nurturing both your body and mind, you create a more harmonious life that supports long-term health.

                            <h6 className='mt-5' >Personalized Guidance for Optimal Health</h6>

                            We understand that navigating health products can be overwhelming, which is why our comprehensive guides offer clear, practical advice tailored to your needs. Whether you’re looking to reduce side effects, enhance your immune function, or improve your lifestyle, we’re here to support you every step of the way. Trust in our commitment to quality and integrity as you explore the natural path to better health.

                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Guides