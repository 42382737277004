import React, { useEffect, useState } from 'react'
import '../../Styles/profile.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios'
import { baseURL } from '../../baseURL.js'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'



const Profile = () => {
    const [GSTIN, SetGSTIN] = useState('')
    const [company, setCompany] = useState('')
    const [add1, setAdd1] = useState('')
    const [add2, setAdd2] = useState('')
    const [city, setCity] = useState('')
    const [landmark, setLandmark] = useState('')
    const [gender, setGender] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')
    const [data, setData] = useState([])
    const [show, setShow] = useState(true)
    const navigate = useNavigate()
    const address = `${add1},${add2},${city},${state},(${pincode}),Landmark:${landmark ? landmark:'No Landmark'}`


    const getDetails = async () => {
        axios({
            method: 'GET',
            withCredentials: true,
            url: `${baseURL}/api/v1/user/getDetails`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('user')}`
            }
        }).then((res) => {
            setData(res.data.data)
        }).catch((err) => {
            setData([]);
        })
        setCompany(data?.company)
        SetGSTIN(data?.GSTIN)
        setGender(data?.gender)
    }

    useEffect(() => {
        getDetails();
        // eslint-disable-next-line 
    }, [])


    const update_Profile = () => {
        if (city===''||state===''||pincode===''||(add1==='' && add2==='')) {
            alert('Required Fields')
        } else {
            axios({
                method: 'PUT',
                url: `${baseURL}/api/v1/user/updateProfile`,
                withCredentials: true,
                data: ({ GSTIN, address, gender, company }),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('user')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    alert('Profile Updated')
                    navigate('/Profile')
                } else {
                    alert('Response Server Error')
                }
            }).catch((err) => {
                const message = err.message;
                const code = message.substring(message.length - 3);
                if (code === "401") {
                    alert("Unauthorised Request")
                } else if (code === "400") {
                    alert("Required Fields")
                } else {
                    alert("Server Error")
                }
            })   
        }
    }
    return (
        <div>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <Col md={8} className='profile-box'>
                        <h5 className='text-center'>Profile Details</h5>
                        <Row className='d-flex justify-content-between' >
                            <Col>
                                <Form.Label className='mt-5'>Name</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder={data.name} readOnly />
                                <Form.Label className='mt-3'>Mobile</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder={data.mobile} readOnly />
                                <Form.Label className='mt-3'>Company/Individual</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='Optional' readOnly={show ? 'readOnly' : ''} onChange={((e) => setCompany(e.target.value))} value={company}/>
                                <Form.Label className='mt-3'>Address</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='Address Line 1' readOnly={show ? 'readOnly' : ''} onChange={((e) => setAdd1(e.target.value))} />
                                <Form.Label className='mt-3'>City</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='Enter City' readOnly={show ? 'readOnly' : ''} onChange={((e) => setCity(e.target.value))} />
                                <Form.Label className='mt-3'>Landmark</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='Landmark' readOnly={show ? 'readOnly' : ''} onChange={((e) => setLandmark(e.target.value))} />
                            </Col>
                            <Col>
                                <Form.Label className='mt-5'>Email</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder={data.email} readOnly />
                                <Form.Label className='mt-3'>GSTIN</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='Optional' readOnly={show ? 'readOnly' : ''} onChange={((e) => SetGSTIN(e.target.value))} value={GSTIN} />
                                <Form.Label className='mt-3'>Gender</Form.Label>
                                <Form.Select className='mb-3' defaultValue="Choose" disabled={show ? 'disabled' : ''} onChange={((e) => setGender(e.target.value))}>
                                    <option value={gender} disabled hidden>{gender ? gender : 'Choose Gender'}</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Others</option>
                                </Form.Select>
                                <Form.Label className='mt-3'>Address</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='Address Line 2' readOnly={show ? 'readOnly' : ''} onChange={((e) => setAdd2(e.target.value))} />
                                <Form.Label className='mt-3'>State</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='State' readOnly={show ? 'readOnly' : ''} onChange={((e) => setState(e.target.value))} />
                                <Form.Label className='mt-3'>Pincode</Form.Label>
                                <Form.Control className='mb-3' type='text' placeholder='Pincode' readOnly={show ? 'readOnly' : ''} onChange={((e) => setPincode(e.target.value))} />
                            </Col>
                        </Row>
                        <div className="text-center mt-3">
                            {
                                show ? <Button onClick={(() => setShow(false))}>Change</Button> : <Button onClick={update_Profile} >Submit</Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Profile