import React, { useState } from 'react'
import '../../Styles/login.css'
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import axios from 'axios'
import { baseURL } from '../../baseURL.js'
import Cookies from 'js-cookie'



const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    axios({
      url: `${baseURL}/api/v1/user/login`,
      data: ({ email, password }),
      method: 'POST',
    }).then((res) => {
      if (res.status === 200) {
        localStorage.setItem('user', res.data.data.refreshToken)
        Cookies.set('refreshToken', res.data.data.refreshToken)
        navigate('/')
        window.location.reload();
      } else {
        alert('Response Server Error')
      }
    }).catch((err) => {
      const message = err.message;
      const code = message.substring(message.length - 3);
      if (code === "404") {
        alert("User Not found")
      } else if (code === "400") {
        alert("Required Fields")
      } else if (code === "401") {
        alert('Invalid Credentials')
      } else {
        alert("Server Error")
      }
    })
  }

  return (
    <div className='back_design'>
      <div className="clouds"></div>
      <div className="main_body">
        <Container>
          <Row>
            <Col className=' login-box mt-5 mb-5' md={4}>
              <Image className='mb-3 mt-3' src={require('../../Images/Sharp logo.png')} fluid />
              <p className='fw-semibold' style={{ color: '#D64779' }} >Welcome Back !!!</p>
              <h4 className='mt-2 mb-5'>Log In</h4>
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3"
              >
                <Form.Control type="email" placeholder="name@example.com" onChange={((e) => setEmail(e.target.value))} />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Password">
                <Form.Control type={show ? "text" : "password"} placeholder="Password" onChange={((e) => setPassword(e.target.value))} />
              </FloatingLabel>
              <Form.Check className='mt-3 mb-5' aria-label="option 1" label="Show Password" onChange={((e) => setShow(e.target.checked))} />
              <div className="text-center mt-5 mb-5">
                <Button onClick={login}>Login</Button>
              </div>
            </Col>
            <Col md={6} >
            </Col>
          </Row>
        </Container>
      </div>
      <p className='m-5'>New User ? Please  <Link to="/Register"> Sign In</Link> </p>
      <p className='m-5'><Link> Forgot Password</Link> </p>
    </div>
  )
}

export default Login