import React, { useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { baseURL } from '../../baseURL';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Setting = () => {
  const [password,setPassword] = useState('')
  const [newPassword,setNewPassword] = useState('')
  const [confirmPassword,setConfirmPassword] = useState('')
  const [show,setShow] = useState(false)
  const navigate = useNavigate()

  const logout = () => {
    axios({
        method: 'POST',
        withCredentials: true,
        url: `${baseURL}/api/v1/user/logout`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('user')}`
        }
    }).then((res) => {
        if (res.status === 200) {
            localStorage.clear();
            Cookies.remove('refreshToken');
            window.location.reload();
        } else {
            alert('Response Server Error')
        }
    }).catch((err) => {
        const message = err.message;
        const code = message.substring(message.length - 3);
        if (code === '401') {
            alert('Unauthorised Request')
        } else {
            alert('Server Error')
        }
    })
}

  const changePassword = () =>{    
    axios({
      url:`${baseURL}/api/v1/user/changePassword`,
      method:'PUT',
      data:({password,newPassword,confirmPassword}),
      withCredentials:true,
      headers:{
        'Authorization':`Bearer ${localStorage.getItem('user')}`
      }
    }).then((res)=>{
      if (res.status===200) {
       alert('Password Changed Succssfully') 
       logout();
       navigate('/')
      } else {
        alert('Response Server Error')
      }     
    }).catch((err)=>{
      if (err.status===400) {
        alert('Required Fields')
      } else if (err.status === 401) {
        alert('Unauthorised Request')
      } else if (err.status === 422) {
        alert('Password Did Not Match')
      } else if (err.status===409) {
        alert('Password Not Valid')
      } else {
        alert('Server Error')
      }
    })
  }


  return (
    <div>
      <Container>
        <Row className='d-flex justify-content-center mt-5' >
          <h4 className='text-center' >Change Password</h4>
          <Col md={4}>
            <FloatingLabel className='mt-5 mb-5' controlId="floatingPassword" label="Old Password">
              <Form.Control type={show ? "text" : "password"} placeholder="Old Password"  onChange={((e)=>setPassword(e.target.value))} />
            </FloatingLabel>
            <FloatingLabel className='mt-5 mb-5' controlId="floatingPassword" label="New Password">
              <Form.Control type={show ? "text" : "password"} placeholder="New Password"  onChange={((e)=>setNewPassword(e.target.value))} />
            </FloatingLabel>
            <FloatingLabel className='mt-5 mb-5' controlId="floatingPassword" label="Confirm Password">
              <Form.Control type={show ? "text" : "password"} placeholder="Confirm Password" onChange={((e)=>setConfirmPassword(e.target.value))}  />
            </FloatingLabel>
            <Form.Check aria-label="option 1" label='Show Password' onChange={((e)=>setShow(e.target.checked))} />
            <div className="text-center mt-5 mb-5">
              <Button onClick={changePassword} variant='success'>Change Password</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Setting