import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { baseURL } from '../../baseURL'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import '../../Styles/cart.css'


const Cart = () => {

  const [data, setData] = useState([])
  const [show, setShow] = useState(true)
  const [finalAmount, setFinalAmount] = useState(0)
  const navigate = useNavigate()

  const token = localStorage.getItem('user')

  const finalBill = async () => {
    axios({
      url: `${baseURL}/api/v1/cart/totalCost`,
      method: 'GET',
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      if (res.status===200) {
        setFinalAmount(res.data.data.finalPrice)
      } else {
        alert('Response Server Error')
      }
    }).catch((err) => {
      if (err.status===401) {
        alert('Login Failure')
      } else{
        alert('Server Error')        
      }
    })
  }

  const cartDetails = async () => {
    axios({
      url: `${baseURL}/api/v1/cart/getCart`,
      method: 'GET',
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      if (res.status === 200) {        
        setData(res.data.data)
      } else {
        alert('Response Server Error')
      }
    }).catch((err) => {
      if (err.status === 401) {
        alert('Login Failure')
      } else if (err.status === 404) {
        setShow(false)
      } else {
        alert('Server Error')
      }
    })
  }

  useEffect(() => {
    cartDetails();
    finalBill()
    //eslint-disable-next-line
  }, [])

  const createCart = async () => {
    axios({
      url: `${baseURL}/api/v1/cart/assignCart`,
      method: 'POST',
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      if (res.status === 200) {
        alert('Cart Created')
        navigate('/')
      } else {
        alert('Response Server Error')
      }
    }).catch((err) => {
      if (err.status === 401) {
        alert('Login Failure')
      } else {
        alert('Server Error')
      }
    })
  }

  const increaseCount = async (_id) => {
    const ProductId = _id
    const count = 1
    axios({
      url: `${baseURL}/api/v1/cart/addToCart`,
      withCredentials: true,
      method: 'POST',
      data: ({ ProductId, count }),
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('user')}`
      }
    }).then((res) => {
      if (res.status === 200) {
        window.location.reload();
      } else {
        alert('Response Error')
      }
    }).catch((err) => {
      if (err.status === 401) {
        alert('Please Login First')
      } else if (err.status === 400) {
        alert('Product Problem')
      } else if (err.status === 404) {
        alert('Create Your Cart')
        navigate('/Cart')
      } else {
        alert('Server Error')
      }
    })
  }

  const decreaseCount = async (ProductId, count) => {
    if (count > 1) {
      axios({
        url: `${baseURL}/api/v1/cart/decreaseCount`,
        method: 'PUT',
        data: ({ ProductId }),
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((res) => {
        if (res.status === 200) {
          window.location.reload()
        } else {
          alert('Response Server Error')
        }
      }).catch((err) => {
        if (err.status === 401) {
          alert('Login failure')
        } else if (err.status === 404) {
          alert('Cart Error')
        } else if (err.status === 400) {
          alert('Id Error')
        } else {
          alert('Server Error')
        }
      })
    } else {
      axios({
        url: `${baseURL}/api/v1/cart/removeProduct`,
        method: 'DELETE',
        data: ({ ProductId }),
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((res) => {
        if (res.status === 200) {
          alert('Product Removed From Cart')
          window.location.reload();
        } else {
          alert('Response Server Error')
        }
      }).catch((err) => {
        if (err.status === 400) {
          alert('Product Error')
        } else if (err.status === 401) {
          alert('Unauthorised Request')
        } else if (err.status === 404) {
          alert('Product Not Found')
        } else {
          alert('Server Error')
        }
      })
    }
  }

  const clearCart = async () => {
    axios({
      url:`${baseURL}/api/v1/cart/clearCart`,
      method:'DELETE',
      withCredentials:true,
      headers:{
        'Authorization': `Bearer ${token}`
      }
    }).then((res)=>{
      if (res.status===200) {
        navigate('/Bills')
      } else {
        alert('Cart Response Error')
      }
    }).catch((err)=>{
      if (err.status===401) {
        alert('Login Failure')
      } else {
        alert('Server Error')
      }
    })
  }

  const placeOrder = async (finalAmount,cart) => {
    const userConfirmed = window.confirm('Please Review Your Order After Placing Order you cart will be Empty')
   if (userConfirmed) {
     axios({
       url:`${baseURL}/api/v1/order/placeOrder`,
       method:'POST',
       withCredentials:true,
       data:({finalAmount,cart}),
       headers:{
         'Authorization':`Bearer ${token}`
       }
     }).then((res)=>{
       if (res.status===200) {
         alert('Order Saved Your Cart Will be Cleared')
         clearCart()
       }else{
         alert('Response Server Error')
       }    
     }).catch((err)=>{
       if (err.status===401) {
         alert('Unauthorised Request')
       } else if (err.status===400) {
         alert('Required Fields')
       } else if (err.status===404) {
        alert('Please Add Address')
        navigate('/Profile')
       } else {
         alert('Server Error')        
       }     
     })
   }
  }
 

  return (
    <div>
      <h3 className='text-center mt-5'>Cart Items</h3>
      {
        show ?
          <div>
            {
              data.ProductId?.length ?
                <div>
                  <Container>
                    <Row className='d-flex justify-content-evenly'>
                      {
                        data.Details.map((item) =>
                          <Col className='mt-5 mb-5' md={3}>
                            <Card>
                              <Card.Img variant="top" src={item.Image[0]} />
                              <Card.Body>
                                <Card.Title>{item.ProductName}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{item.ProductId}</Card.Subtitle>
                                <Card.Text>Size : {item.PackingSize} <br /> Price : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" /></svg> {item.Price} \- <br />Total : {data.count[data.ProductId.indexOf(item._id)] * item.Price}</Card.Text>
                                <div className='d-flex justify-content-center'>
                                  <Button onClick={(() => decreaseCount(item._id, data.count[data.ProductId.indexOf(item._id)]))} className='design' > {data.count[data.ProductId.indexOf(item._id)] === 1 ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                  </svg> : <div>-</div>} </Button>
                                  <p className='count'>{data.count[data.ProductId.indexOf(item._id)]}</p>
                                  <Button className='design' onClick={(() => increaseCount(item._id))} >+</Button>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      }
                    </Row>
                    <Row>
                      <Col>
                        <h4 className='text-center mt-5 mb-5'>Total Bill : <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" /></svg>{finalAmount}+<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" /></svg>100 = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" /></svg>{finalAmount+100 }/-(Delivery Charge Included) </h4>
                        <div className="text-center mb-5">
                          <Button onClick={(() => placeOrder(finalAmount,data))} variant='warning'>Place Order</Button>
                          {/* <Button onClick={(()=>console.log(data.ProductId.length))}>show</Button> */}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                :
                <div style={{minHeight:'45vw',alignContent:'center'}}>
                  <h4 className='text-center'>No Items added In Your Cart</h4>
                </div>
            }
          </div>
          :
          <div className='text-center mt-5 mb-5'>
            <h6 className='mt-5 mb-5'>Your Cart is Not Created Please Create Your Cart</h6>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-cart mt-5 mb-5" viewBox="0 0 16 16">
              <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
            </svg> <br />
            <Button onClick={createCart} variant='primary'>Create Cart</Button>
          </div>
      }

    </div>
  )
}

export default Cart