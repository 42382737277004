import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'


const Educational = () => {
    return (
        <div>
            <Container>
                <h4 className='text-center mt-5 mb-5' >Our Background Basics</h4>
                <Row className='d-flex justify-content-center'>
                    <Col md={8}>
                        <p className='mb-5' style={{textAlign:'justify'}}>
                            <h6>Institution Overview :</h6>
                            Our Institution is dedicated to promoting the rich heritage of Ayurvedic and Khadi products, rooted deeply in the principles of sustainability and traditional Indian craftsmanship. Founded by a passionate entrepreneur with a profound understanding of botany, our mission is to harness the natural healing properties of indigenous plants while supporting local artisans. This commitment to quality and authenticity ensures that every product reflects the essence of Ayurveda and the timeless values of Khadi.

                            <h6 className='mt-5'>Heritage and Inspiration :</h6>

                            The inspiration behind our Institution stems from the legacy of our co-founder, an esteemed ex-freedom fighter whose unwavering dedication to the nation transcended the battle for independence. His experiences during that transformative period instilled a strong sense of social responsibility and a desire to empower rural communities. This heritage of resilience and purpose drives us to create products that not only honor traditional practices but also uplift local artisans and contribute to their livelihoods.

                            <h6 className='mt-5'>Expertise in Zoology :</h6>

                            With a deep-rooted knowledge of botany, our founder meticulously selects the finest natural ingredients for our Ayurvedic formulations. His expertise ensures that every product is crafted with precision, utilizing time-tested methods that respect the environment and promote holistic wellness. By integrating scientific understanding with ancient wisdom, we are able to create formulations that are effective, safe, and beneficial for our customers.

                            <h6 className='mt-5'>Commitment to Sustainability :</h6>

                            In line with the principles of Khadi, our Institution is committed to sustainability and ethical practices. We prioritize eco-friendly sourcing, ensuring that all materials are harvested responsibly and contribute to the welfare of local ecosystems. By championing Khadi as a symbol of self-reliance and dignity, we not only promote a healthier lifestyle but also advocate for a sustainable future. Together, we strive to keep the spirit of our founders alive, fostering a community that values tradition, knowledge, and ecological balance.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Educational