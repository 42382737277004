import React, { useEffect, useState } from 'react'
import '../../Styles/body.css'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row"
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button"
import axios from 'axios';
import { baseURL } from '../../baseURL';



const Body = () => {
    const [data, setData] = useState([])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [bussiness_Type, setBussiness_Type] = useState('')
    const [querry_Type, setQuerry_Type] = useState('')
    const [message, setMessage] = useState('')
    const [search, setSearch] = useState('')

    const navigate = useNavigate();

    const getProduct = () => {
        axios({
            url: `${baseURL}/api/v1/product/getProduct`,
            method: 'GET',
        }).then((res) => {
            setData(res.data.data.productDetails)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getProduct();
        // eslint-disable-next-line
    }, [])

    const addToCart = (_id) => {
        const ProductId = _id
        const count = 1
        axios({
            url: `${baseURL}/api/v1/cart/addToCart`,
            withCredentials: true,
            method: 'POST',
            data: ({ ProductId, count }),
            headers:{
                'Authorization':`Bearer ${localStorage.getItem('user')}`
            }
        }).then((res) => {            
            if (res.status === 200) {
                alert('Item Added to Cart')
                window.location.reload()
            } else {
                alert('Response Error')
            }
        }).catch((err) => {
            if (err.status === 401) {
                alert('Please Login First')
            } else if (err.status === 400) {
                alert('Product Problem')
            } else if (err.status === 404) {
                alert('Create Your Cart')
                navigate('/Cart')
            } else {
                alert('Server Error')
            }
        })
    }

    const submit = () => {
        axios({
            url: `${baseURL}/api/v1/grievance/saveGrievance`,
            method: 'POST',
            data: ({ name, mobile, email, bussiness_Type, message, querry_Type })
        }).then((res) => {
            if (res.status === 200) {
                alert('Grievance Saved')
                window.location.reload()
            } else {
                alert('Response Server Error')
            }
        }).catch((err) => {
            if (err.status === 400) {
                alert('Please Fill the Compelete Form')
            } else {
                alert('Server Error')
            }
        })
    }

    const searchFields = () => {
        axios({
            url: `${baseURL}/api/v1/product/searchProduct`,
            method: 'POST',
            data: ({ search })
        }).then((res) => {
            if (res.status === 200) {
                navigate('/Searched', { state: { data: res.data.data.product }})                
            } else {
                alert('Response Server Error')
            }
        }).catch((err) => {
            if (err.status === 400) {
                alert('Enter Something to Search')
            } else {
                alert('Product Not Found')
            }
        })
    }

    return (
        <div>
            <div className="below_nav">
                <Container>
                    <Row className='d-flex justify-content-between Body_Box' >
                        <Col md={5}>
                            <h6>Sharp Laboratories </h6>
                            <h1>Most Trusted Ayurvedic Pharmacy in Kashi / Banaras</h1>
                            <p>Sharp Laboratories is one of the leading Manufacturer of Herbal Products, Ayurvedic Classical & Patient Medicines & Khadi Gramodyog Products etc, <b> Embrace the essence of tradition with the vibrant herbs of the Vindhya Ranges and the sacred waters of the holy River Ganga</b> since 1985 in Varanasi, Uttar Pradesh.</p>
                            <h4> 100K+</h4>
                            <h5>Satisfied Families every year</h5>
                        </Col>
                        <Col md={6}>
                            <h4>काश्यां हि काशते काशी सर्वप्रकाशिका। <br /> सा काशी विदिता येन तेन प्राप्ता ही काशिका।।</h4>
                            <Image src={require('../../Images/baba vishwanath.jpg')} fluid rounded />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="below_body">
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col md={6}>
                            <div className="d-flex">
                                <Form.Control size='lg' type="text" placeholder="Search" onChange={((e) => setSearch(e.target.value))} /><svg onClick={searchFields} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-search form_div" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="products mt-4">

                {/* Ayurvedic Container */}

                <Container>
                    <Row>
                        <Col className='d-flex justify-content-between mb-3'>
                            <h3 className='text-start'>Ayurvedic</h3>
                            <Link to='/ProductDetails/:Ayurvedic'>View all <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                            </svg></Link>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-between mb-5'>
                        {
                            data.filter((item) => item.category === 'Ayurvedic').slice(0, 4).map((field) =>
                                <Col className='products-box' md={2}>
                                    <Image className='p-2' src={field.Image[0]} fluid rounded />
                                    <h6>{field.ProductName}</h6>
                                    <p>₹ {field.Price}</p>
                                    <Link onClick={(() => addToCart(field._id))} style={{ textDecoration: "none", color: "#fff" }}><div className="Buy_Now_Button">
                                        Buy Now <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cart2" viewBox="0 0 16 16">
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l1.25 5h8.22l1.25-5zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </div></Link>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
                <hr />

                {/* Khadi Herbal */}
                <Container>
                    <Row>
                        <Col className='d-flex justify-content-between mb-3'>
                            <h3 className='text-start'>Khadi Herbal</h3>
                            <Link to='/ProductDetails/:Khadi Herbal' >View all <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                            </svg></Link>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-between mb-5'>
                        {
                            data.filter((item) => item.category === 'Khadi Herbal').slice(0, 4).map((field) =>
                                <Col className='products-box' md={2}>
                                    <Image className='p-2' src={field.Image[0]} fluid rounded />
                                    <h6>{field.ProductName}</h6>
                                    <p>₹ {field.Price}</p>
                                    <Link onClick={(() => addToCart(field._id))} style={{ textDecoration: "none", color: "#fff" }}><div className="Buy_Now_Button">
                                        Buy Now <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cart2" viewBox="0 0 16 16">
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l1.25 5h8.22l1.25-5zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </div></Link>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
                <hr />

                {/* Khadi Gramodoyog */}
                <Container>
                    <Row>
                        <Col className='d-flex justify-content-between mb-3'>
                            <h3 className='text-start'>Khadi Gramodyog</h3>
                            <Link to='/ProductDetails/:Khadi Gramodyog' >View all <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                            </svg>
                            </Link>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-between mb-5'>
                        {
                            data.filter((item) => item.category === 'Khadi Gramodyog').slice(0, 4).map((field) =>
                                <Col className='products-box' md={2}>
                                    <Image className='p-2' src={field.Image[0]} fluid rounded />
                                    <h6>{field.ProductName}</h6>
                                    <p>₹ {field.Price}</p>
                                    <Link onClick={(() => addToCart(field._id))} style={{ textDecoration: "none", color: "#fff" }}>
                                        <div className="Buy_Now_Button">
                                            Buy Now <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cart2" viewBox="0 0 16 16">
                                                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l1.25 5h8.22l1.25-5zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                            </svg>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        }
                    </Row>
                </Container>

                {/* Suggestions */}

                <Container>
                    <h3 className='text-center mt-5 mb-5'>Suggestion</h3>
                    <Row className='d-flex justify-content-center'>
                        <Col md={8}>
                            <Row className='d-flex justify-content-between '>
                                <Col md={4} className='text-center contact-box'>
                                    <h6>Contact Information</h6>
                                    <p className='mt-5 mb-5'>Suggest Us Something which we can Improve</p>
                                    <p className='text-start mt-5 '><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5" />
                                    </svg> : <b>9450535983</b> </p>
                                    <p className='text-start mt-5 '><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                                    </svg> : <b>sharplaboratories1055@gmail.com</b> </p>
                                    <p className='text-start mt-5 mb-5 '><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                    </svg> :  Plot No 121, 122 Maheshpur Industrial Estate, Kashi Vidya Peeth Block Road, Industrial Estate, Varanasi - 221106</p>
                                    <b><p className='text-start mt-5'>We are Situtuated on land of Shiv and the oldest city of every civilization Kashi/Banaras .</p></b>
                                </Col>
                                <Col md={7} className='text-center'>
                                    <Row>
                                        <Col>
                                            <Form className='mb-5' >
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label className='d-flex justify-content-start'>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Name"
                                                        className='no-outline-input'
                                                        onChange={((e) => setName(e.target.value))}
                                                    />
                                                </Form.Group>
                                            </Form>
                                            <Form className='mb-5'>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label className='d-flex justify-content-start'>Mobile Number</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter Mobile Number"
                                                        className='no-outline-input mobile'
                                                        onChange={((e) => setMobile(e.target.value))}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col>
                                            <Form className='mb-5'>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label className='d-flex justify-content-start'>Email address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter email"
                                                        className='no-outline-input'
                                                        onChange={((e) => setEmail(e.target.value))}
                                                    />
                                                </Form.Group>
                                            </Form>
                                            <Form className='mb-5'>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label className='d-flex justify-content-start'>Bussiness Name</Form.Label>
                                                    <Form.Select aria-label="Default select example" onChange={((e) => setBussiness_Type(e.target.value))}>
                                                        <option hidden selected > Company/Individual</option>
                                                        <option >Company</option>
                                                        <option >Individual</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <div>
                                        <h6 className='text-start'>Select Querry type</h6>
                                        <div className=" d-flex justify-content-between text-start">
                                            <Form.Select aria-label="Default select example" onChange={((e) => setQuerry_Type(e.target.value))}>
                                                <option hidden selected >Querry Type</option>
                                                <option value="Retail Inquiry">Retail Inquiry</option>
                                                <option value="Bulk Inquiry">Bulk Inquiry</option>
                                                <option value="Genral Inquiry">Genral Inquiry</option>
                                                <option value="Grievances">Grievances</option>
                                                <option value="Suggestion">Suggestion</option>
                                            </Form.Select>
                                        </div>
                                        <Form className='mt-3'>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className='d-flex justify-content-start'>Message</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Message"
                                                    className='no-outline-input'
                                                    onChange={((e) => setMessage(e.target.value))}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </div>
                                    <div className="text-end mt-2">
                                        <Button type='button' onClick={submit}>Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <hr />
            </div>
        </div>
    )
}

export default Body