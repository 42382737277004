import React, { useState } from 'react'
import '../../Styles/register.css'
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import axios from 'axios'
import { baseURL } from '../../baseURL.js'


const Register = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const Create_Account = () => {    
      axios({
        url: `${baseURL}/api/v1/user/register`,
        method: 'POST',
        data: ({ name, mobile, password, email, confirmPassword })
      }).then((res) => {
        if (res.status === 200) {
          alert('Account Created Successfully')
          navigate('/Login')
        } else {
          alert('Response Server Error')
        }
      }).catch((err) => {
        const message = err.message;
        const code = message.substring(message.length - 3);
        if (code === "409") {
          alert("User Already Exists")
        } else if (code === "400") {
          alert("Required Fields")
        } else if (code === "422") {
          alert('Password Mismatch')
        }else {
          alert("Server Error")
        }
      })
  }
  return (
    <div>
      <div className="main_body">
        <Container>
          <Row className='d-flex justify-content-between'>
            <Col md={6} >
            </Col>
            <Col className=' login-box mt-5 mb-5' md={4}>
              <Image className='mb-3 mt-3' src={require('../../Images/Sharp logo.png')} fluid />
              <p className='fw-semibold' style={{ color: '#D64779' }} >You are Welcome here !!!</p>
              <h4 className='mt-2 mb-5'>Create Account</h4>
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="mb-3"
              >
                <Form.Control type="text" placeholder="Name" onChange={((e) => setName(e.target.value))} />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3"
              >
                <Form.Control type="text" placeholder="name@example.com" onChange={((e) => setEmail(e.target.value))} />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Mobile Number"
                className="mb-3"
              >
                <Form.Control type="email" placeholder="name@example.com" onChange={((e) => setMobile(e.target.value))} />
              </FloatingLabel>
              <FloatingLabel className='mb-3' controlId="floatingPassword" label="Password">
                <Form.Control type={show ? "text" : "password"} placeholder="Password" onChange={((e) => setPassword(e.target.value))} />
              </FloatingLabel>
              <FloatingLabel className='mb-3' controlId="floatingPassword" label="Confirm Password">
                <Form.Control type={show ? "text" : "password"} placeholder="Confirm Password" onChange={((e) => setConfirmPassword(e.target.value))} />
              </FloatingLabel>
              <Form.Check className='mt-3 mb-5' aria-label="option 1" label="Show Password" onChange={((e) => setShow(e.target.checked))} />
              <div className="text-center mt-5 mb-5">
                <Button onClick={Create_Account}>Create Account</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <p className='text-end m-5'>Already a User <Link to="/Login">Login</Link> </p>
    </div>
  )
}

export default Register