import React, { useEffect, useState } from 'react'
import '../../Styles/products.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios'
import { baseURL } from '../../baseURL'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import { useNavigate } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'

const Products = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()

  const getProduct = () => {
    axios({
      url: `${baseURL}/api/v1/product/getProduct`,
      method: 'GET'
    }).then((res) => {
      if (res.status === 200) {
        setData(res.data.data.productDetails)
      } else {
        alert('Response Server Error')
      }
    }).catch((err) => {
      if (err.status === 404) {
        alert('No Products Till Now')
      } else {
        alert('Server Error')
      }
    })
  }

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, [])


  const addToCart = (_id) => {
    const ProductId = _id
    const count = 1
    axios({
      url: `${baseURL}/api/v1/cart/addToCart`,
      withCredentials: true,
      method: 'POST',
      data: ({ ProductId, count }),
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('user')}`
      }
    }).then((res) => {
      if (res.status === 200) {
        alert('Item Added to Cart')
        window.location.reload()
      } else {
        alert('Response Error')
      }
    }).catch((err) => {
      if (err.status === 401) {
        alert('Please Login First')
      } else if (err.status === 400) {
        alert('Product Problem')
      } else if (err.status === 404) {
        alert('Create Your Cart')
        navigate('/Cart')
      } else {
        alert('Server Error')
      }
    })
  }


  return (
    <div className='mt-3 mb-3'>
      <h2 className='m-3'>Ayurvedic Medicines</h2>
      {
        data.filter((item) => item.category === 'Ayurvedic').map((product) =>
          <Container className='shadow-lg p-3 mb-5 bg-body-tertiary rounded'>
            <Row className='d-flex justify-content-center'>
              <Col md={5} >
                <h4>{product.ProductName}</h4>
                <p className='fw-bold mb-4'>{product.ProductId.substring(0, 2) === 'AC' ? '(Classical)' : '(Patient)'}</p>
                <p><b>Product Id </b>: {product.ProductId}</p>
                <h6>Packing Size</h6>
                <div className="size-box mb-3">{product.PackingSize}</div>
                <h6>Price</h6>
                <div className="price-box mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                </svg> {product.Price} /-</div>
                <p><b>Shelf Life</b> : {product.shelf_life}</p>
                <p style={{ textAlign: 'justify' }}><b>Description</b> : {product.description}</p>
                <div className="text-center">
                  <Button onClick={(() => addToCart(product._id))} variant='info'>Add To Cart</Button>
                </div>
              </Col>
              <Col md={7} >
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Disclamer</Accordion.Header>
                    <Accordion.Body>This Image is Only For Reference Packaging may differ according to Size.</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Carousel fade>
                  {
                    product.Image.map((image) =>
                      <Carousel.Item>
                        <Image src={image} fluid rounded />
                      </Carousel.Item>
                    )
                  }
                </Carousel>
              </Col>
            </Row>
          </Container>

        )
      }
      <hr />
      <h2 className='m-3'>Khadi Herbal</h2>
      {
        data.filter((item) => item.category === 'Khadi Herbal').map((product) =>
          <Container className='shadow-lg p-3 mb-5 bg-body-tertiary rounded'>
            <Row className='d-flex justify-content-center'>

              <Col md={5} >
                <h4 className='mb-4' >{product.ProductName}</h4>
                <p className='mb-4' ><b>Product Id </b>: {product.ProductId}</p>
                <h6>Packing Size</h6>
                <div className="size-box mb-3">{product.PackingSize}</div>
                <h6>Price</h6>
                <div className="price-box mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                </svg> {product.Price} /-</div>
                <p><b>Shelf Life</b> : {product.shelf_life}</p>
                <p style={{ textAlign: 'justify' }} ><b>Description</b> : {product.description}</p>
                <div className="text-center">
                  <Button onClick={(() => addToCart(product._id))} variant='danger'>Add To Cart</Button>
                </div>
              </Col>
              <Col md={7} >
              <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Disclamer</Accordion.Header>
                    <Accordion.Body>This Image is Only For Reference Packaging may differ according to Size.</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Carousel fade>
                  {
                    product.Image.map((image) =>
                      <Carousel.Item>
                        <Image src={image} fluid rounded />
                      </Carousel.Item>
                    )
                  }
                </Carousel>
              </Col>
            </Row>
          </Container>
        )

      }
      <hr />
      <h2 className='m-3' >Khadi Gramodyog</h2>
      {
        data.filter((item) => item.category === 'Khadi Gramodyog').map((product) =>
          <Container className='shadow-lg p-3 mb-5 bg-body-tertiary rounded'>
            <Row className='d-flex justify-content-center'>
              <Col md={5} >
                <h4 className='mb-4' >{product.ProductName}</h4>
                <p className='mb-4' ><b>Product Id </b>: {product.ProductId}</p>
                <h6>Packing Size</h6>
                <div className="size-box mb-3">{product.PackingSize}</div>
                <h6>Price</h6>
                <div className="price-box mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                </svg> {product.Price} /-</div>
                <p><b>Shelf Life</b> : {product.shelf_life}</p>
                <p style={{ textAlign: 'justify' }} ><b>Description</b> : {product.description}</p>
                <div className="text-center">
                  <Button onClick={(() => addToCart(product._id))} variant='success'>Add To Cart</Button>
                </div>
              </Col>
              <Col md={7} >
              <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Disclamer</Accordion.Header>
                    <Accordion.Body>This Image is Only For Reference Packaging may differ according to Size.</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Carousel fade>
                  {
                    product.Image.map((image) =>
                      <Carousel.Item>
                        <Image src={image} fluid rounded />
                      </Carousel.Item>
                    )
                  }
                </Carousel>
              </Col>
            </Row>
          </Container>
        )
      }
    </div>
  )
}

export default Products