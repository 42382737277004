import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'


const Privacy = () => {
    return (
        <Container>
            <Row className='d-flex justify-content-center mb-5'>
                <h3 className='text-center mt-5'>Privacy Policy</h3>
                <Col md={8}>
                    <p style={{ textAlign: 'justify' ,fontSize:'1.05rem'}}>
                        <h6 className='mt-5' >Privacy Policy Overview :</h6>
                        At our company, we prioritize your privacy and are committed to protecting your personal information. We understand that trust is essential in any relationship, and we want to assure you that your credentials will never be exchanged with anyone. We respect your privacy and recognize the importance of maintaining the confidentiality of your information, allowing you to engage with our services without concerns about unauthorized access or misuse.

                        <h6 className='mt-5' >Communication and Data Handling :</h6>

                        To enhance your experience, we have chosen not to offer any mode of online payments. This decision allows us to minimize the handling of sensitive payment information, ensuring that your financial details remain secure. Our only form of communication will be through email, which serves as a direct channel for relevant updates and offers. By relying solely on email, we can keep our interactions streamlined and focused, while ensuring that you receive important information directly from us.

                        <h6 className='mt-5' >Data Storage Practices :</h6>

                        In our commitment to safeguarding your privacy, we do not store your search data or any other personal information beyond what is necessary to communicate with you about our offerings. The only piece of information we retain is your mobile number, which allows us to inform you about special promotions and updates related to our products and services. This practice enables us to provide personalized communication without compromising your privacy or overwhelming you with unnecessary data collection.

                        <h6 className='mt-5' >Partnership and Collaboration :</h6>

                        We are proud to announce our partnership with Khadi India for certain products. This collaboration not only allows us to offer you high-quality Khadi goods but also aligns with our commitment to sustainability and ethical practices. By sourcing from Khadi India, we support local artisans and promote traditional craftsmanship. Your trust is of utmost importance to us, and we will always strive to protect your information as we provide you with exceptional products that reflect our values and dedication to the community.

                        <h6 className='mt-5' >Your Rights and Choices :</h6>

                        We believe in empowering our users to make informed choices about their data. You have the right to request information regarding the data we hold and to opt out of any communications at any time. Should you have any questions or concerns about our privacy practices, we encourage you to reach out to us. Our commitment to transparency means we are here to address any inquiries you may have, ensuring that you feel confident in your relationship with us.
                    </p>
                </Col>
            </Row>
        </Container>

    )
}

export default Privacy