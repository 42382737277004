import React from 'react'
import '../../Styles/footer.css';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from 'react-bootstrap/Col'
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <div className="main_footer">
                <Container>
                    <Row>
                        <Col className='d-flex justify-content-between mt-5'>
                            <h4> <Image src={require('../../Images/Sharp logo.png')} fluid rounded /> Sharp Laboratories</h4>
                            <p><svg onClick={(()=>window.scroll(0,0))} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z" />
                            </svg></p>
                        </Col>
                    </Row>
                    <hr style={{color:'#fff'}}/>
                </Container>
                <Container>
                    <Row>
                        <Col md={5} className='d-flex' >
                        <div className="company m-5">
                            <h3>Company</h3>
                            <ul>
                                <li><Link className='Links' to="/About_Us" >About Us</Link></li>
                                <li><Link className='Links' to="/Products" >Products</Link></li>
                                <li><Link className='Links' to="/Services" >Services</Link></li>
                                <li><Link className='Links' to="/Contact_Us" >Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="resources m-5">
                        <h3>Resources</h3>
                            <ul>
                                <li><Link className='Links' to="/Educational" >Educational</Link></li>
                                <li><Link className='Links' to="/Pediatrics" >Pediatrics</Link></li>
                                <li><Link className='Links' to="/Nutrition" >Nutrition</Link></li>
                                <li><Link className='Links' to="/Guides" >Medication Guides</Link></li>
                            </ul>
                        </div>
                        </Col>
                        <Col md={4} >
                        </Col>
                    </Row>
                    <hr style={{color:"#fff"}} />
                </Container>
                <Container>
                    <Row className='foot d-flex justify-content-between'>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                        <p>Copyright © 2024 Sharp Laboratories | Developed by Coronial Technologies</p>
                        </Col>
                        <Col className='text-end' xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Link to="/Terms" style={{color:"#fff",textDecoration:'none'}} >Terms of Use </Link>|<Link to="/Privacy" style={{color:"#fff",textDecoration:'none'}} > Privacy Policy </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer