import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'


const Bills = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Container>
                <Row className='d-flex justify-content-center mt-5 mb-5'>
                    <Col md={8}>
                        <p style={{minHeight:'40vw',alignContent:'center'}} className='text-center fs-3 fw-bolder'>Your Order is Saved with us Our Marketing Team Will Connect to you Shortly.</p>
                        <div className="text-center mb-3">
                            <Button onClick={(()=>{navigate('/'); window.location.reload()})} variant='warning'>Back To Home</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Bills