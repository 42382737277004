import React from 'react'
import '../../Styles/services.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const Services = () => {
  return (
    <div>
      <Container>
        <h3 className='text-center mt-5 mb-5'>Our Services</h3>
        <h5 className='mt-3'>Our Delivery Areas</h5>
        <Row className='d-flex justify-content-center'>
          <Col md={8}>
            <p style={{ textAlign: 'justify', fontWeight: "700" }}>We are excited to announce that we currently deliver to five key areas, ensuring our customers receive their orders promptly and efficiently. However, we understand the importance of accessibility, and we're pleased to extend our delivery service across PAN India for all orders. No matter where you are located, we are committed to bringing our products to you, making your experience seamless and convenient. Thank you for choosing us!</p>
          </Col>
        </Row>
        <h5 className='mt-3'>Payments</h5>
        <Row className='d-flex justify-content-center'>
          <Col md={8}>
            <p style={{ textAlign: 'justify', fontWeight: "700" }} >We would like to inform our customers that we currently do not offer online payment options. To place an order, please do it online  and call us to confirm your order details. Once confirmed, you will need to make your payment via bank transfer or UPI. After completing the transfer, kindly send us a confirmation of the transaction. Once we verify your payment, we will promptly process and deliver your order. Thank you for your understanding and support!</p>
          </Col>
        </Row>
        <h5 className='mt-3'>Delivery Methods</h5>
        <Row className='d-flex justify-content-center'>
          <Col md={8}>
            <p style={{ textAlign: 'justify', fontWeight: "700" }}>We are proud to utilize reliable delivery methods to ensure your orders reach you safely and on time. Our primary delivery service is through the government-operated Speed Post, a trusted courier service offered by India Post. This official service guarantees secure and efficient delivery across our designated areas and beyond. By choosing Speed Post, we ensure that your packages are handled with care and arrive promptly, reflecting our commitment to excellent customer service. Thank you for choosing us for your delivery needs!</p>
          </Col>
        </Row>
        <h5 className='mt-3'>Policy</h5>
        <Row className='d-flex justify-content-center'>
          <Col md={8}>
            <p style={{ textAlign: 'justify', fontWeight: "700" }}>Our delivery policy is designed to ensure a smooth and secure shopping experience. We require full payment to be completed before processing any orders. Once your order has been delivered, please note that we have a no-return policy, provided the product's shelf life remains intact. We encourage you to review your order carefully before completing your purchase. Thank you for your understanding and support!</p>
          </Col>
        </Row>
        <h5 className='mt-3'>Social Services</h5>
        <Row className='d-flex justify-content-center'>
          <Col md={8}>
            <p style={{ textAlign: 'justify', fontWeight: "700" }}>We are committed to giving back to the community through various social initiatives. In Varanasi, we organize health check-up camps and distribute free products, ensuring that essential services and resources reach those in need. These events reflect our dedication to promoting health and well-being in the community, and we are proud to play a part in making a positive impact. We look forward to continuing these efforts and helping to improve the lives of those around us!</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Services