import React from 'react'
import '../../Styles/about.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'


const About = () => {
  return (
    <div>
      <Container>
        <h3 className='text-center mt-5 mb-3'>
          Our Founder's and Co-Founder's
        </h3>
        <Row className='d-flex justify-content-around mt-5 mb-5'>
          <Col md={3}>
            <Card>
              <Card.Img variant="top" src={require('../../Images/father.jpg')} />
              <Card.Body>
                <Card.Title className='text-center'>Founder</Card.Title>
                <Card.Text>
                  <p className='text-center'>Lt. Dr Suresh Tiwari <br /><b>(1955-2002)</b></p>
                  <p className='text-justify'>Prmoted Rural Empowerment by Training Herbs Indentification , Collection and Processing. </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Img variant="top" src={require('../../Images/grandfather.jpg')}/>
              <Card.Body>
                <Card.Title className='text-center'>Co-Founder/Mentor</Card.Title>
                <Card.Text>
                  <p className='text-center'>Lt. Ramapati Tiwari <br /><b>(1922-2003)</b></p>
                  <p className='text-justify'>An Ex-Freedom Fighter who always Promtes Swadeshi and follwed same for his whole life.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Img variant="top" src={require('../../Images/grandmother.jpg')}/>
              <Card.Body>
                <Card.Title className='text-center'>Co-Founder</Card.Title>
                <Card.Text>
                  <p className='text-center'>Lt. Dulari Devi <br /><b>(1923-2021)</b></p>
                  <p className='text-justify'>Promted Gramodyog In Female Villagers by making them their own village Industry.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Our Partners */}

      <Container>
        <h4 className='text-center'>Our Current Working Partners</h4>
        <Row className='d-flex justify-content-evenly mt-5 mb-5'>
          <Col md={2}>
            <Card>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title className='text-center partners'>Maneesh Tiwari</Card.Title>
                <Card.Text>
                  <p className='text-center'>(On Ground Head)</p>
                  <p style={{fontSize:"small"}} className='text-center'>(PG Diploma in Export/Import Mangement, NIEIM)</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title className='text-center partners'>Girish Chandra Tiwari</Card.Title>
                <Card.Text>
                  <p className='text-center'>(Marketing Head)</p>
                  <p className='text-center'>(PGDCA)</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title className='text-center partners'>Aacharya Mayank Tiwari</Card.Title>
                <Card.Text>
                  <p style={{fontSize:"small"}} className='text-center'>(Dr Sampurnanand Sanskrit University Varanasi)</p>
                  <p style={{fontSize:"small"}} className='text-center'>(Vyakarnaacharya)</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title className='text-center partners'>Drx Vaibhav Tiwari</Card.Title>
                <Card.Text>
                  <p className='text-center'>(IMS B.H.U)</p>
                  <p className='text-center'>M.Pharm(AY)</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title className='text-center partners'>Dr Ayush Tiwari</Card.Title>
                <Card.Text>
                  <p className='text-center'>(B.H.U)</p>
                  <p className='text-center'>PhD. Economics</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title className='text-center partners'>Dr Mrinalini Tiwari</Card.Title>
                <Card.Text>
                  <p style={{fontSize:"small"}} className='text-center'>(BBA University Lucknow)</p>
                  <p style={{fontSize:"small"}} className='text-center'>( PhD. Food & Nutrition)</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Our Story */}

      <Container>
        <Row className='d-flex justify-content-center mb-5'>
          <Col className='ourstory' md={8}>
             <h4 className='text-center mb-3 mt-3'>Our Story</h4>
            <p style={{textAlign:'justify'}}>
              Our Institutions ,taking care of the social responsibilities,is expensing  our share of profit in establishing Health Camps,Employment training Camps,Women Empowerment,Provide training camp of Herbs Identification , Collection and Processing etc to the rural & forest areas. Our Institutions provides employment oppurtunities to the people of rural and forest areas by involving the inhabitants of those areas in herbs-collection.
              Sharp Laboratories was established in the year 1985, on the auspicious occasion of Dhanvantari Jayanti, held on the jayanti of the god of Ayurveda-God Dhanvantari Ji. <br /> <br />
              The company was established by Late Dr. Suresh Tiwari,who held the M.Sc. PhD. degree in Zoology,with the vision & motto to provide Health & Medical facilities by Ayurveda  to the under-privileged and economically weak patients specially women.
              Incepted in the year 1985, Sharp Laboratories has been serving the society through its premium quality range of herbal and other natural products. The ultimate aim with which the company was established was to produce products made of Ayurvedic Jadi-Butis like Himalayan drugs, etc. In the most hygienic way possible. Today, we are acknowledged as an emerging preparer,  of Herbal Products, Herbal Classical and Patient Medicines, Health Food Items, Health Tonics, etc after long Research. Manufactured by making use of finest quality herbal extracts, our products are a testimony to superior quality, with no side effects at all. With our satisfactory Families increasing day by day , we are surging ahead with confidence and our products are upto the mark . We offer products as per the requirements of our clients.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default About